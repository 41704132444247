import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./footerLanding.scss"
import CustomMarkdown from "../../utils/customMarkdown"

const FooterLanding = ({disclaimer}) => {

    const data = useStaticQuery(graphql`
        {
            strapiFooter {
                Column1 {
                  DisplayOnMobile
                  Title
                  URL
                  Link_nofollow
                }
                Column2 {
                  DisplayOnMobile
                  URL
                  Title
                  Link_nofollow
                }
                Copyright
                Disclaimer
            }

            strapiSiteSettings{
                LogoLight{
                    url
                    localFile{
                        childImageSharp{
                            gatsbyImageData(
                                width: 180
                                placeholder: BLURRED
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
            }
        }
    `)

    return(
        <>
        <section className="footer-section-landing">
            <div className="footer-wrapper">

                <div className="footer__copyright">
                    <p>{data.strapiFooter.Disclaimer}</p>
                    {disclaimer?<CustomMarkdown children={disclaimer}></CustomMarkdown>:""}
                </div>

            </div>
        </section>
        </>
    )
}

export default FooterLanding